import React from "react";
import Layout from "../layouts/Layout";
import styled from "styled-components";
import MetodoPiuVeloce from "../sections/MetodoPiuVeloce";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const StyledStaticPage = styled.div`
  .static-page {
    &__title {
      text-align: center;
    }

    &__slug a {
      text-decoration: none;
      color: #000;
      &:hover {
        text-decoration: underline;
      }
    }

    &__header {
      height: 300px;

      @media(max-width: 768px) {
        .wrapper {
          justify-content: center !important;
          text-align: center;
        }
      }

      background: -webkit-linear-gradient(
        273deg,
        rgba(242, 242, 242, 0.7) 0%,
        rgba(206, 212, 216, 0.7) 65%,
        rgba(206, 212, 216, 0.698) 100%
      );
      background: -o-linear-gradient(
        273deg,
        rgba(242, 242, 242, 0.7) 0%,
        rgba(206, 212, 216, 0.7) 65%,
        rgba(206, 212, 216, 0.698) 100%
      );
      background: -ms-linear-gradient(
        273deg,
        rgba(242, 242, 242, 0.7) 0%,
        rgba(206, 212, 216, 0.7) 65%,
        rgba(206, 212, 216, 0.698) 100%
      );
      background: -moz-linear-gradient(
        273deg,
        rgba(242, 242, 242, 0.7) 0%,
        rgba(206, 212, 216, 0.7) 65%,
        rgba(206, 212, 216, 0.698) 100%
      );
      background: linear-gradient(
        177deg,
        rgba(242, 242, 242, 0.7) 0%,
        rgba(206, 212, 216, 0.7) 65%,
        rgba(206, 212, 216, 0.698) 100%
      );

      .intro {
        color: #527191;
      }

      .title {
        @media(min-width: 768px) {
          padding-left: 3em;
        }
      }

      .content {
        @media(max-width: 768px) {
          flex-direction: column-reverse;
          align-items: center;
        }
      }
    }
  }

  .section {
    display: flex;
    align-items: center;
    @media (max-width: 992px) {
      flex-direction: column;
    }
    &__title {
      color: #e7c700;
      font-size: 30px;
      min-width: 400px;
      align-self: flex-start;
      text-align: left;
      padding-top: 1em;
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 100px;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    @media (max-width: 1400px) {
      flex-wrap: wrap;
    }

    &__block {
      padding: 0 2em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3em;
      flex: 50%;
    }

    &__title {
      margin: auto;
      font-weight: 500;
      font-size: 18px;
    }

    &__description {
      max-width: 400px;
      text-align: center;
    }
  }
`;

export default function vantaggi({ className }) {
  return (
    <Layout 
      helmetTitle="Scopri i vantaggi della formula 100% online - Prestitifaidate"
      helmetDescription="Scopri i vantaggi della formula 100% online: da casa tua, tasso fisso agevolato, procedura veloce online e tutta la sicurezza per la tua privacy."
    >
      <StyledStaticPage id="page-content">
        <div className="static-page__header">
          <div className="wrapper container d-flex align-items-center position-relative justify-content-between h-100">
            <div className="d-flex align-items-center content">
              <StaticImage
                src="../assets/images/testatina-vantaggi.png"
                className={className}
                alt="Vantaggi"
                placeholder="blurred"
                layout="fixed"
                width={177}
                height={177}
                objectFit="contain"
                quality={75}
                loading="lazy"
              />
              <div className="d-flex flex-column title mb-3 mb-lg-0">
                <span className="fw-bold fs-1">Vantaggi</span>
                <span className="intro">Se risparmi, è Prestiti Fai da Te</span>
              </div>
            </div>
          </div>
        </div>
        <div id="page-content" className="container mx-auto">
          <div className="static-page__slug mb-5 mt-4 fs-5">
            {" "}
            <Link to="/" className="fw-bold">
              HOME
            </Link>{" "}
            &gt; <Link to="/vantaggi/">Vantaggi</Link>
          </div>
          <h1 className="static-page__title mb-5 display-5 fw-bold">
            I vantaggi di Prestiti Fai da Te
          </h1>
          <div className="static-page__content">
            <section className="section">
              <div className="section__content">
                <p className="text-center">
                  Vivi solo il meglio del tuo prestito: la comodità di riceverlo
                  senza muoverti da casa tua, la convenienza del tasso fisso agevolato, la velocità della procedura online e tutta la sicurezza
                  per la tua privacy.
                </p>
                <div className="items">
                  <div className="items__block">
                    <StaticImage
                      src="../assets/images/vantaggi-100-online.png"
                      className="img-fluid mx-auto"
                      alt="100% online"
                      placeholder="blurred"
                      layout="fixed"
                      width={140}
                      height={140}
                      quality={90}
                      loading="lazy"
                    />
                    <p className="pt-4 text-uppercase text-center fw-bolder items__title mb-3">
                      100% online
                    </p>
                    <p className="items__description">
                      L’intera procedura è facile e conveniente. Il modo più
                      semplice per avere a disposizione liquidità immediata,
                      senza giustificativi di spesa o motivazioni. E ricevi
                      tutto comodamente dal divano di casa tua. Così puoi
                      realizzare tutti i progetti che hai sempre rimandato. Sei
                      pronto?
                    </p>
                  </div>

                  <div className="items__block">
                    <StaticImage
                      src="../assets/images/vantaggi-zero-costi-di-gestione.png"
                      className="img-fluid mx-auto"
                      alt="Zero costi di gestione"
                      placeholder="blurred"
                      layout="fixed"
                      width={140}
                      height={140}
                      quality={90}
                      loading="lazy"
                    />
                    <p className="pt-4 text-uppercase text-center fw-bolder items__title mb-3">
                      zero costi di gestione
                    </p>
                    <p className="items__description">
                      Vai sul sicuro perché risparmi: grazie alla formula fai da
                      te, azzeri i costi di gestione, limitando al massimo gli
                      oneri per le pratiche. Ti basta compilare il form con i
                      tuoi dati e la tua busta paga o pensione per ottenere
                      immediatamente quello che ti serve.
                    </p>
                  </div>

                  <div className="items__block">
                    <StaticImage
                      src="../assets/images/vantaggi-1-ora.png"
                      className="img-fluid mx-auto"
                      alt="Esito in 1 ora"
                      placeholder="blurred"
                      layout="fixed"
                      width={140}
                      height={140}
                      quality={90}
                      loading="lazy"
                    />
                    <p className="pt-4 text-uppercase text-center fw-bolder items__title mb-3">
                      basta 1 ora
                    </p>
                    <p className="items__description">
                      E ottieni subito l'approvazione del tuo prestito. ll tuo
                      consulente di fiducia Prestiti Fai daTe, ti seguirà passo
                      passo lungo tutta l'istruttoria, creando il tuo piano di
                      finanziamento personalizzato e flessibile. Così potrai
                      vivere in libertà ed essere sempre sereno. Pensiamo noi a
                      tutto!
                    </p>
                  </div>

                  <div className="items__block">
                    <StaticImage
                      src="../assets/images/testatina-vantaggi.png"
                      className="img-fluid mx-auto"
                      alt="TASSO AGEVOLATO"
                      placeholder="blurred"
                      layout="fixed"
                      width={140}
                      height={140}
                      quality={90}
                      loading="lazy"
                    />
                    <p className="pt-4 text-uppercase text-center fw-bolder items__title mb-3">
                      TASSO FISSO AGEVOLATO
                    </p>
                    <p className="items__description">
                      Se sei un pensionato o un dipendente pubblico, il tasso di
                      interesse rimane basso e fisso lungo tutto il periodo di
                      ammortamento del prestito. Hai anche la possibilità di
                      saldare il debito anticipatamente e beneficiare del
                      recupero con abbuono degli interessi non maturati.
                    </p>
                  </div>

                  <div className="items__block">
                    <StaticImage
                      src="../assets/images/vantaggi-privacy.png"
                      className="img-fluid mx-auto"
                      alt="Privacy"
                      placeholder="blurred"
                      layout="fixed"
                      width={140}
                      height={140}
                      quality={90}
                      loading="lazy"
                    />
                    <p className="pt-4 text-uppercase text-center fw-bolder items__title mb-3">
                      totale riservatezza
                    </p>
                    <p className="items__description">
                      Le opportunità di finanziamento di Prestiti Fai da Te sono
                      sicure, comode e rispettano la tua privacy. Ti basta il
                      cedolino della pensione o la busta paga per ottenere
                      l'importo che ti serve. E non ti chiediamo giustificativi
                      di spesa o destinazione d'uso del tuo prestito.
                    </p>
                  </div>

                  <div className="items__block">
                    <StaticImage
                      src="../assets/images/vantaggi-soddisfatti.png"
                      className="img-fluid mx-auto"
                      alt="100% clienti soddisfatti"
                      placeholder="blurred"
                      layout="fixed"
                      width={140}
                      height={140}
                      quality={90}
                      loading="lazy"
                    />
                    <p className="pt-4 text-uppercase text-center fw-bolder items__title mb-3">
                      100% clienti soddisfatti
                    </p>
                    <p className="items__description">
                      Di noi, puoi fidarti davvero. Entra anche tu nella nostra
                      famiglia e goditi tutte le agevolazioni di un prestito su
                      misura per le tue esigenze. In più, hai tutta la sicurezza
                      di avere un partner super affidabile sempre al tuo fianco.
                      Per noi, garantiscono tutti i nostri clienti!
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </StyledStaticPage>
      <MetodoPiuVeloce className="mt-5" />
    </Layout>
  );
}
